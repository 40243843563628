import React from "react";
import { Link } from "react-router-dom";

function refreshPage() {
  window.reload();
  window.location.reload();
  window.scrollTo(0, 0);
}

class Header extends React.Component {
  handleMenuClose = () => {
    document.getElementById("Menu-input").checked = false;
  };

  render() {
    return (
      <header className="Header-header">
        <div className="Header-div">
          <input id="Menu-input" type="checkbox" />
          <label className="Menu-label" htmlFor="Menu-input">
            <div className="Button-div"></div>
          </label>
          <ul className="Menu-ul">
            <li className="Menu-li"><Link className="Timer-Link" to="/timer" onClick={refreshPage}>Asylum Sanctorium Timer</Link></li>
            <li className="Menu-li"><Link className="Discord-Link" to="/discord" onClick={refreshPage}>Discord</Link></li>
            <li className="Menu-li"><Link className="Submit-Link" to="/submit" onClick={refreshPage}>Submit Build</Link></li>
            <li className="Menu-li"><Link className="Tales-Link" to="/tales" onClick={refreshPage}>Tales of Tribute (ToT) Top 5 Tips</Link></li>
            <li className="Menu-li"><Link className="PvP-Link" to="/pvp-magic-dragonknight-corrosive" onClick={refreshPage}>Magic Dragonknight Corrosive (PvP)</Link></li>
            <li className="Menu-li"><Link className="PvP-Link" to="/pvp-magic-necromancer-bomber" onClick={refreshPage}>Magic Necromancer Bomber (PvP)</Link></li>
            <li className="Menu-li"><Link className="PvP-Link" to="/pvp-magic-sorcerer-executioner" onClick={refreshPage}>Magic Sorcerer Executioner (PvP)</Link></li>
            <li className="Menu-li"><Link className="PvP-Link" to="/pvp-magic-warden-heal-mother" onClick={refreshPage}>Magic Warden Heal Mother (PvP)</Link></li>
          </ul>
          <Link className="Home-Link" to="/home" onClick={refreshPage}>
            <span className="Header-span">
              <h1 className="Header-h1">MainBuilds.com</h1>
            </span>
          </Link>
        </div>
      </header>
    );
  }
}

export default Header;

import React, { useState } from "react";
import * as Skills from "../../skill-library";

function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}
function Modal() {
    function Skill(props) {
        return <img class="Skills-img" alt="Skills-img"
            onMouseOverCapture={(event) => handleHover(props.name, event)}
            onClickCapture={(event) => handleHover(props.name, event)}
            onMouseMoveCapture={() => handleHover()}
            onTouchStartCapture={() => handleHover()}
            src={props.src} {...props}>
        </img>;
    }
    const handleHover = (skillName, event) => {
        if (skillName === hoveredSkill)
            return;
        switch (skillName) {
            case "BlightedBlastbones":
                const FB1 = getOffset(event.target);
                setPosition(FB1);
                setHoveredSkill(Skills.BlightedBlastbones);
                break;
            case "SkeletalArcanist":
                const FB2 = getOffset(event.target);
                setPosition(FB2);
                setHoveredSkill(Skills.SkeletalArcanist);
                break;
            case "AvidBoneyard":
                const FB3 = getOffset(event.target);
                setPosition(FB3);
                setHoveredSkill(Skills.AvidBoneyard);
                break;
            case "MysticSiphon":
                const FB4 = getOffset(event.target);
                setPosition(FB4);
                setHoveredSkill(Skills.MysticSiphon);
                break;
            case "RuinousScythe":
                const FB5 = getOffset(event.target);
                setPosition(FB5);
                setHoveredSkill(Skills.RuinousScythe);
                break;
            case "DawnbreakerOfSmiting":
                const FBU = getOffset(event.target);
                setPosition(FBU);
                setHoveredSkill(Skills.DawnbreakerOfSmiting);
                break;
            case "ResistantFlesh":
                const BB1 = getOffset(event.target);
                setPosition(BB1);
                setHoveredSkill(Skills.ResistantFlesh);
                break;
            case "NecroticPotency":
                const BB2 = getOffset(event.target);
                setPosition(BB2);
                setHoveredSkill(Skills.NecroticPotency);
                break;
            case "HealingContingencyNecromancer":
                const BB3 = getOffset(event.target);
                setPosition(BB3)
                setHoveredSkill(Skills.HealingContingencyNecromancer);
                break;
            case "SummonersArmor":
                const BB4 = getOffset(event.target);
                setPosition(BB4);
                setHoveredSkill(Skills.SummonersArmor);
                break;
            case "SpiritGuardian":
                const BB5 = getOffset(event.target);
                setPosition(BB5);
                setHoveredSkill(Skills.SpiritGuardian);
                break;
            case "PestilentCollossus":
                const BBU = getOffset(event.target);
                setPosition(BBU);
                setHoveredSkill(Skills.PestilentCollossus);
                break;
            default:
                setHoveredSkill(null);
        }
    };
    const [hoveredSkill, setHoveredSkill] = useState(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const display = hoveredSkill ? "block" : "none";
    return (
        <div className="BC-div">
            <div className="Tooltip-div" style={{ display: display, top: position.top + 232/* , left: position.left */ }}>
                <h1 className="SkillName-h1">{hoveredSkill?.name}</h1>
                <img className="Tooltip-img" src={`${hoveredSkill?.icon}`} alt="Tooltip-img"></img>
                <h1 className="Description-h1">Description:</h1>
                <p className="Tooltip-p" dangerouslySetInnerHTML={{ __html: hoveredSkill?.effect_1 }}></p>
                <h1 className="Morph-h1">Morph:</h1>
                <p className="Morph-p" dangerouslySetInnerHTML={{ __html: hoveredSkill?.effect_2 }}></p>
            </div>
            <div className="IntroductionGoldRoad-div">
                <div className="Title-div">
                    <h1 className="Title-h1">Magic Necromancer Bomber</h1>
                </div>
                <div className="Top-div">
                    <div className="Creator-div">
                        <h1 className="Creator-h1">Build Creator:</h1>
                        <h1 className="Creator-h1"><a href="https://gamerdvr.com/gamer/sorcerer-main/videos" target="_blank" rel="noopener noreferrer">Sorcerer Main</a></h1>
                    </div>
                    <div className="Patch-div">
                        <h1 className="Patch-h1">Updated for:</h1>
                        <h1 className="Patch-h1">Gold Road</h1>
                    </div>
                </div>
            </div>
            <div className="FB-div">
                <fieldset className="FB-fieldset">
                    <legend className="FB-legend">
                        <h1 className="Bar-h1">Frontbar</h1>
                    </legend>
                    <div className="Skills-div">
                        <Skill name="BlightedBlastbones" src="https://mainbuilds.com/media/skills/class/necromancer/grave-lord/blastbones/blighted-blastbones.png" alt="blighted-blastbones"></Skill>
                        <Skill name="SkeletalArcanist" src="https://mainbuilds.com/media/skills/class/necromancer/grave-lord/skeletal-mage/skeletal-arcanist.png" alt="skeletal-arcanist"></Skill>
                        <Skill name="AvidBoneyard" src="https://mainbuilds.com/media/skills/class/necromancer/grave-lord/boneyard/avid-boneyard.png" alt="avid-boneyard"></Skill>
                        <Skill name="MysticSiphon" src="https://mainbuilds.com/media/skills/class/necromancer/grave-lord/shocking-siphon/mystic-siphon.png" alt="mystic-siphon"></Skill>
                        <Skill name="RuinousScythe" src="https://mainbuilds.com/media/skills/class/necromancer/bone-tyrant/death-scythe/ruinous-scythe.png" alt="ruinous-scythe"></Skill>
                        <div className="Space-div"></div>
                        <Skill name="DawnbreakerOfSmiting" src="https://mainbuilds.com/media/skills/guild/fighters-guild/dawnbreaker/dawnbreaker-of-smiting.png" alt="dawnbreaker-of-smiting"></Skill>
                    </div>
                </fieldset>
            </div >
            <div className="BB-div">
                <fieldset className="BB-fieldset">
                    <legend className="BB-legend">
                        <h1 className="Bar-h1">Backbar</h1>
                    </legend>
                    <div className="Skills-div">
                        <Skill name="ResistantFlesh" src="https://mainbuilds.com/media/skills/class/necromancer/living-death/render-flesh/resistant-flesh.png" alt="resistant-flesh"></Skill>
                        <Skill name="NecroticPotency" src="https://mainbuilds.com/media/skills/class/necromancer/bone-tyrant/bitter-harvest/necrotic-potency.png" alt="necrotic-potency"></Skill>
                        <Skill name="HealingContingencyNecromancer" src="https://mainbuilds.com/media/skills/guild/mages-guild/ulfsilds-contingency/ulfsilds-contingency.png" alt="healing-contingency-necromancer"></Skill>
                        <Skill name="SummonersArmor" src="https://mainbuilds.com/media/skills/class/necromancer/bone-tyrant/bone-armor/summoners-armor.png" alt="summoners-armor"></Skill>
                        <Skill name="SpiritGuardian" src="https://mainbuilds.com/media/skills/class/necromancer/living-death/spirit-mender/spirit-guardian.png" alt="spirit-guardian"></Skill>
                        <div className="Space-div"></div>
                        <Skill name="PestilentCollossus" src="https://mainbuilds.com/media/skills/class/necromancer/grave-lord/frozen-colossus/pestilent-colossus.png" alt="pestilent-colossus"></Skill>
                    </div>
                </fieldset>
            </div>
            <div className="Slotted-div">
                <table className="Slotted-table">
                    <thead>
                        <tr>
                            <th className="Slotted-th">Button</th>
                            <th className="Slotted-th">Frontbar</th>
                            <th className="Slotted-th">Backbar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="White-tr">
                            <td className="White-td"><h1 className="X-h1"><span className="X-span">X</span></h1></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/necromancer/grave-lord/blighted-blastbones" target="_blank" rel="noopener noreferrer">Blighted Blastbones</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/necromancer/living-death/resistant-flesh" target="_blank" rel="noopener noreferrer">Resistant Flesh</a></td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Transparent-td"><h1 className="Y-h1"><span className="Y-span">Y</span></h1></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/necromancer/grave-lord/skeletal-arcanist" target="_blank" rel="noopener noreferrer">Skeletal Arcanist</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/necromancer/bone-tyrant/necrotic-potency" target="_blank" rel="noopener noreferrer">Necrotic Potency</a></td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td"><h1 className="B-h1"><span className="B-span">B</span></h1></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/necromancer/grave-lord/avid-boneyard" target="_blank" rel="noopener noreferrer">Avid Boneyard</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/guild/mages-guild/ulfsilds-contingency" target="_blank" rel="noopener noreferrer">Healing Contingency</a></td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Transparent-td"><h1 className="LB-h1"><span className="LB-span">LB</span></h1></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/necromancer/grave-lord/mystic-siphon" target="_blank" rel="noopener noreferrer">Mystic Siphon</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/necromancer/bone-tyrant/summoners-armor" target="_blank" rel="noopener noreferrer">Summoners Armor</a></td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td"><h1 className="RB-h1"><span className="RB-span">RB</span></h1></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/necromancer/bone-tyrant/ruinous-scythe" target="_blank" rel="noopener noreferrer">Ruinous Scythe</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/necromancer/living-death/spirit-guardian" target="_blank" rel="noopener noreferrer">Spirit Guardian</a></td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Transparent-td"><h1 className="LB-h1"><span className="LB-span">LB</span><span className="Space-span">RB</span></h1></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/necromancer/grave-lord/pestilent-colossus" target="_blank" rel="noopener noreferrer">Pestilent Colossus</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/guild/psijic-order/temporal-guard" target="_blank" rel="noopener noreferrer">Temporal Guard</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="Gear-div">
                <table className="Gear-table">
                    <thead>
                        <tr>
                            <th className="Slotted-th">Slot</th>
                            <th className="Slotted-th">Set</th>
                            <th className="Slotted-th">Weapon</th>
                            <th className="Slotted-th">Weight</th>
                            <th className="Slotted-th">Trait</th>
                            <th className="Slotted-th">Enchantment</th>
                            <th className="Slotted-th">Poison</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="White-tr">
                            <td className="White-td">Frontbar One</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/innate-axiom" target="_blank" rel="noopener noreferrer">Innate Axxiom</a></td>
                            <td className="White-td">Lightning Staff</td>
                            <td className="BW-td">-</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/weapon/charged" target="_blank" rel="noopener noreferrer">Charged</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-absorb-stamina/truly-superb-glyph-of-absorb-stamina" target="_blank" rel="noopener noreferrer">Glyph of Absorb Stamina</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Frontbar Two</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Backbar One</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/dark-convergence" target="_blank" rel="noopener noreferrer">Dark Convergence</a></td>
                            <td className="White-td">Restoration Staff</td>
                            <td className="BW-td">-</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/weapon/decisive" target="_blank" rel="noopener noreferrer">Decisive</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-prismatic-onslaught/truly-superb-glyph-of-prismatic-onslaught" target="_blank" rel="noopener noreferrer">Glyph of Prismatic Onslaught</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Backbar Two</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Head</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/balorgh" target="_blank" rel="noopener noreferrer">Balorgh</a></td>
                            <td className="BW-td">-</td>
                            <td className="Medium-td">Medium</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/armor/impenetrable" target="_blank" rel="noopener noreferrer">Impenetrable</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-prismatic-defense" target="_blank" rel="noopener noreferrer">Glyph of Prismatic Defense</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Shoulder</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/sets/balorgh" target="_blank" rel="noopener noreferrer">Balorgh</a></td>
                            <td className="BG-td">-</td>
                            <td className="Medium-td">Medium</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/traits/armor/impenetrable" target="_blank" rel="noopener noreferrer">Impenetrable</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-prismatic-defense" target="_blank" rel="noopener noreferrer">Glyph of Prismatic Defense</a></td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Chest</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/innate-axiom" target="_blank" rel="noopener noreferrer">Innate Axxiom</a></td>
                            <td className="BW-td">-</td>
                            <td className="Heavy-td">Heavy</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/armor/reinforced" target="_blank" rel="noopener noreferrer">Reinforced</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-prismatic-defense" target="_blank" rel="noopener noreferrer">Glyph of Prismatic Defense</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Hands</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/sets/innate-axiom" target="_blank" rel="noopener noreferrer">Innate Axxiom</a></td>
                            <td className="BG-td">-</td>
                            <td className="Medium-td">Medium</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/traits/armor/impenetrable" target="_blank" rel="noopener noreferrer">Impenetrable</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-prismatic-defense" target="_blank" rel="noopener noreferrer">Glyph of Prismatic Defense</a></td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Belt</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/dark-convergence" target="_blank" rel="noopener noreferrer">Dark Convergence</a></td>
                            <td className="BW-td">-</td>
                            <td className="Light-td">Light</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/armor/impenetrable" target="_blank" rel="noopener noreferrer">Impenetrable</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-prismatic-defense" target="_blank" rel="noopener noreferrer">Glyph of Prismatic Defense</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Pants</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/sets/innate-axiom" target="_blank" rel="noopener noreferrer">Innate Axxiom</a></td>
                            <td className="BG-td">-</td>
                            <td className="Medium-td">Medium</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/traits/armor/impenetrable" target="_blank" rel="noopener noreferrer">Impenetrable</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-prismatic-defense" target="_blank" rel="noopener noreferrer">Glyph of Prismatic Defense</a></td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Shoes</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/armor-of-the-trainee" target="_blank" rel="noopener noreferrer">Armor of the Trainee</a></td>
                            <td className="BW-td">-</td>
                            <td className="Medium-td">Medium</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/armor/impenetrable" target="_blank" rel="noopener noreferrer">Impenetrable</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-prismatic-defense" target="_blank" rel="noopener noreferrer">Glyph of Prismatic Defense</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Necklace</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/sets/sea-serpents-coil" target="_blank" rel="noopener noreferrer">Sea Serpent"s Coil</a></td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/traits/jewelry/triune" target="_blank" rel="noopener noreferrer">Triune</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-increase-physical-harm/truly-superb-glyph-of-increase-physical-harm" target="_blank" rel="noopener noreferrer">Glyph of Increase Physical Harm</a></td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Ring One</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/dark-convergence" target="_blank" rel="noopener noreferrer">Dark Convergence</a></td>
                            <td className="BW-td">-</td>
                            <td className="BW-td">-</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/jewelry/triune" target="_blank" rel="noopener noreferrer">Triune</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-increase-physical-harm/truly-superb-glyph-of-increase-physical-harm" target="_blank" rel="noopener noreferrer">Glyph of Increase Physical Harm</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Ring Two</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/sets/dark-convergence" target="_blank" rel="noopener noreferrer">Dark Convergence</a></td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/traits/jewelry/triune" target="_blank" rel="noopener noreferrer">Triune</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-increase-physical-harm/truly-superb-glyph-of-increase-physical-harm" target="_blank" rel="noopener noreferrer">Glyph of Increase Physical Harm</a></td>
                            <td className="BG-td">-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="North-div">
                <div className="NW-div">
                    <fieldset className="Consumable-fieldset">
                        <legend className="Consumable-legend">
                            <h1 className="Consumable-h1">Consumable</h1>
                        </legend>
                        <div className="Consumable-div">
                            <img class="Consumable-img" src="/media/consumable/bewitched-sugar-skulls-food.png" alt="bewitched-sugar-skills-food"></img>
                            <div class="Name-div">
                                <span className="Name-span">
                                    <h1 className="Name-h1"><a href="https://eso-hub.com/en/food-drinks/delicacies/bewitched-sugar-skulls" target="_blank" rel="noopener noreferrer">Bewitched Sugar Skulls</a></h1>
                                </span>
                                <div className="Description-div">
                                    <span className="Description-span">Increase maximum <span className="Health-span">Health</span> by 4620, maximum <span className="Stamina-span">Stamina</span> and <span className="Magicka-span">Magicka</span> by 4250, and <span className="HR-span">Health Recovery</span> by 462 for 2 hours.</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="NE-div">
                    <fieldset className="Consumable-fieldset">
                        <legend className="Mundus-legend">
                            <h1 className="Mundus-h1">Mundus</h1>
                        </legend>
                        <div className="Mundus-div">
                            <img class="Mundus-img" src="/media/mundus/the-lover-mundus.png" alt="the-lover-mundus"></img>
                            <div class="Name-div">
                                <span className="Name-span">
                                    <h1 className="Name-h1"><a href="https://eso-hub.com/en/mundus-stones/the-lover" target="_blank" rel="noopener noreferrer">The Lover</a></h1>
                                </span>
                                <div className="Description-div">
                                    <span className="Description-span">Increases Physical and Spell Penetration by 2744.</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className="South-div">
                <div className="SW-div">
                    <fieldset className="Potion-fieldset">
                        <legend className="Potion-legend">
                            <h1 className="Potion-h1">Potion</h1>
                        </legend>
                        <div className="Potion-div">
                            <img class="Potion-img" src="/media/potion/essence-of-health-potion.png" alt="essence-of-health-potion"></img>
                            <div class="Name-div">
                                <span className="Name-span">
                                    <h1 className="Name-h1"><a href="https://en.uesp.net/wiki/Online:Potions" target="_blank" rel="noopener noreferrer">Essence of Health</a></h1>
                                </span>
                                <div className="Description-div">
                                    <span className="Description-span">Restore 8536 <span className="Health-span">Health</span>, 7582 <span className="Magicka-span">Magicka</span>, and 7582 <span className="Stamina-span">Stamina</span> immediately. Grant <span className="Gold-span">Major Fortitude</span>, <span className="Gold-span">Major Intellect</span>, and <span className="Gold-span">Major Endurance</span> for 47.6 seconds.</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="SE-div">
                    <fieldset className="Race-fieldset">
                        <legend className="Race-legend">
                            <h1 className="Race-h1">Race</h1>
                        </legend>
                        <div className="Race-div">
                            <img class="Race-img" src="/media/race/altmer-race.png" alt="altmer"></img>
                            <div class="Name-div">
                                <span className="Name-span">
                                    <h1 className="Name-h1"><a href="https://eso-hub.com/en/skills/racial/high-elf-skills" target="_blank" rel="noopener noreferrer">High Elf</a></h1>
                                </span>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className="Affliction-div">
                <h1 className="Affliction-h1">Affliction</h1>
                <table className="Affliction-table">
                    <thead>
                        <th className="Vampire-th">Vampire</th>
                        <th className="None-th">None</th>
                        <th className="Werewolf-th">Werewolf</th>
                    </thead>
                    <tbody>
                        <tr className="White-tr">
                            <td className="Vampire-td">No</td>
                            <td className="None-td">Yes</td>
                            <td className="Werewolf-td">No</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="Attributes-div">
                <h1 className="Attributes-h1">Attribute Points</h1>
                <table className="Attribute-table">
                    <thead>
                        <th className="Magicka-th">Magicka</th>
                        <th className="Health-th">Health</th>
                        <th className="Stamina-th">Stamina</th>
                    </thead>
                    <tbody>
                        <tr className="White-tr">
                            <td className="Magicka-td">64</td>
                            <td className="Health-td">0</td>
                            <td className="Stamina-td">0</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="Champion-div">
                <h1 className="Champion-h1">Slotted Champion Points</h1>
                <div className="Warfare-div">
                    <h1 className="Warfare-h1">Warfare</h1>
                    <ul className="Champion-ul">
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/duelists-rebuff" target="_blank" rel="noopener noreferrer">Duelist"s Rebuff</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/ironclad" target="_blank" rel="noopener noreferrer">Ironclad</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/occult-overload" target="_blank" rel="noopener noreferrer">Occult Overload</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/unassailable" target="_blank" rel="noopener noreferrer">Unassailable</a></span></li>
                    </ul>
                </div>
                <div className="Fitness-div">
                    <h1 className="Fitness-h1">Fitness</h1>
                    <ul className="Champion-ul">
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/boundless-vitality" target="_blank" rel="noopener noreferrer">Boundless Vitality</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/fortified" target="_blank" rel="noopener noreferrer">Fortified</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/pains-refuge" target="_blank" rel="noopener noreferrer">Pain"s Refuge</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/slippery" target="_blank" rel="noopener noreferrer">Slippery</a></span></li>
                    </ul>
                </div>
                <div className="Craft-div">
                    <h1 className="Craft-h1">Craft</h1>
                    <ul className="Champion-ul">
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/liquid-efficiency" target="_blank" rel="noopener noreferrer">Liquid Efficiency</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/rationer" target="_blank" rel="noopener noreferrer">Rationer</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/steeds-blessing" target="_blank" rel="noopener noreferrer">Steed's Blessing</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/treasure-hunter" target="_blank" rel="noopener noreferrer">Treasure Hunter</a></span></li>
                    </ul>
                </div>
                <div className="GIF-div">
                    <h1 className="GIF-h1">Build in action!</h1>
                    <img className="GIF-img" src="/media/gif/magic-necromancer-bomber.gif" alt="magic-necromancer-bomber-gif"></img>
                </div>
            </div>
        </div >
    );
}

export default Modal;
import React, { useState } from "react";

const Tales = () => {
    const [visibleDiv, setVisibleDiv] = useState(null);

    const toggleDisplay = (divName) => {
        setVisibleDiv(visibleDiv === divName ? null : divName);
    };

    return (
        <div className="Content-div">
            <fieldset className="Tales-fieldset">
                <legend className="Tales-legend">
                    <h1 className="Tales-h1">Tales of Tribute (ToT) Top 5 Tips</h1>
                </legend>
                <div className="Tales-div">
                    <p className="Tales-p">
                        <span className="Number-span">1.)</span> Convert your 1-power base patron cards to the 2-coin Writs of Coin.
                    </p>
                    <button className="Why-button" onClick={() => toggleDisplay("WhyOne")}>Why?</button>
                    {visibleDiv === "WhyOne" && (
                        <div className="Why-div">
                            <p className="Why-p">
                                <span className="Why-span">This will give you the best chance at having a minimum of 6 gold on the third hand.</span>
                            </p>
                        </div>
                    )}

                    <p className="Tales-p">
                        <span className="Number-span">2.)</span> Stick to buying 2 patron deck's cards maximum.
                    </p>
                    <button className="Why-button" onClick={() => toggleDisplay("WhyTwo")}>Why?</button>
                    {visibleDiv === "WhyTwo" && (
                        <div className="Why-div">
                            <p className="Why-p">
                                <span className="Why-span">You win via card combinations and buying more cards of different types is counter to this.</span>
                            </p>
                        </div>
                    )}

                    <p className="Tales-p">
                        <span className="Number-span">3.)</span> Kill agents whenever possible.
                    </p>
                    <button className="Why-button" onClick={() => toggleDisplay("WhyThree")}>Why?</button>
                    {visibleDiv === "WhyThree" && (
                        <div className="Why-div">
                            <p className="Why-p">
                                <span className="Why-span">Letting your opponent keep their agent on the field is the fastest way to lose.</span>
                            </p>
                        </div>
                    )}

                    <p className="Tales-p">
                        <span className="Number-span">4.)</span> Unlock the additional patron decks and upgrade all cards.
                    </p>
                    <button className="Why-button" onClick={() => toggleDisplay("WhyFour")}>Why?</button>
                    {visibleDiv === "WhyFour" && (
                        <div className="Why-div">
                            <p className="Why-p">
                                <span className="Why-span">Access to all the decks and cards will help you to learn how to play and counter them.</span>
                            </p>
                        </div>
                    )}

                    <p className="Tales-p">
                        <span className="Number-span">5.)</span> Have fun! You're going to win and lose a lot of games.
                    </p>
                    <button className="Why-button" onClick={() => toggleDisplay("WhyFive")}>Why?</button>
                    {visibleDiv === "WhyFive" && (
                        <div className="Why-div">
                            <p className="Why-p">
                                <span className="Why-span">Learn from each loss and don't take this luck-based game too seriously.</span>
                            </p>
                        </div>
                    )}
                </div>
            </fieldset>
        </div>
    );
};

export default Tales;
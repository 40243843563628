import React from "react";
import { Link } from "react-router-dom";

function refreshPage() {
  window.reload();
  window.location.reload();
  window.scrollTo(0, 0);
}

class Footer extends React.Component {
  render() {
    return (
      <footer className="Footer-footer">
        <div className="Footer-div">
          <div className="D-div">
            <Link
              className="Disclaimer-Link"
              to="/disclaimer"
              onClick={refreshPage}
            >
              <span className="Disclaimer-span">Disclaimer</span>
            </Link>
          </div>
          <div className="Pipe-div">
            <h1 className="Pipe-h1">|</h1>
          </div>
          <div className="Years-div">
            <h1 className="Years-h1">&copy; {new Date().getFullYear()} Main Builds</h1>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
